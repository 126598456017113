<template>
  <div style="background-color: #f7f7f7;">
    <div class="container-fluid p-12">
      <p class="text-center">
        © ២០២០ រក្សាសិទ្ធិគ្រប់យ៉ាងដោយ <span class="font-weight-bold">អគ្គនាយកដ្ឋានសំណង់</span>
      </p>
      <p class="text-center">
        © 2020 Copyright by General Department of Construction
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
  .p-12 {
    padding: 12px;
  }
</style>
