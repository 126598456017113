<template>
  <div>
    <Header/>
    <Project/>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/views/projects/_components/Header'
import Footer from '@/views/projects/_components/Footer'
import Project from '@/views/projects/_components/Project'

export default {
  name: 'ShowForPublic',
  components: {
    Project,
    Footer,
    Header
  }
}
</script>

<style scoped>

</style>
