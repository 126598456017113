<template>
  <div>
    <div v-if="isLoading">
      <under-development :description="$t('string.fetchData')"/>
    </div>
    <div v-else>
      <div v-if="project">
        <div class="qr-code-tips-container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="qr-tips-occupancy text-center">
                  <p class="font-header" style="font-size: 18px;">
                    ព័ត៌មានអំពីសំណង់
                    <span class="pull-right">
                      <localization-switcher :enabled-style="false"/>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="project-content">
              <div class="col-md-12 mb-2 mt-2">
                <h3 class="text-bold">
                  <template v-if="project.construction_type">
                    {{ project.construction_type['name_' + $i18n.locale] }}
                  </template>
                  <template v-else>{{ $t('string.na') }}</template>
                </h3>
                <p><i class="fa fa-user"></i> {{ owners }}</p>
                <p>
                  <i class="fa fa-tag"></i>
                  <template v-if="project.project_type">
                    {{ project.project_type['name_' + $i18n.locale] }}
                  </template>
                </p>
              </div>
              <div class="col-md-12 mb-2">
                <GmapMap
                  :center="{lat:project.lat, lng:project.lng}"
                  :zoom="15"
                  map-type-id="terrain"
                  style="width: 100%; height: 320px">
                  <GmapMarker
                    :icon="{url: '/img/markers/blue20x20.png'}"
                    :position="{lat:project.lat, lng:project.lng}"/>
                </GmapMap>
              </div>
              <div class="col-md-12">
                <div class="row" v-if="project.detail_values">
                  <div class="col-md-12">
                    <table class="table table-bordered table-striped">
                      <tbody>
                      <tr v-for="(item, key) in project.detail_values"
                          :key="key">
                        <td>{{ item['label_' + $i18n.locale] }}</td>
                        <td>{{ item['value_' + $i18n.locale] }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <project-building-detail :project="project"/>
              <div class="col-md-12">
                <div class="row mb-2 mt-2" v-viewer>
                  <div transition-duration="0.3s"
                       item-selector=".item"
                       class="col-md-12">
                    <div class="row">
                      <div v-masonry-tile
                           class="col-md-2 col-xs-3 mb-2"
                           :key="index"
                           v-for="(item, index) in project.cci_images">
                        <img :src="`${baseUrl}/${item.src}`" class="img-responsive"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-else>
        <div class="row">
          <div class="col-md-12">
            <template v-if="message">
              <under-development :description="message['message_' + $i18n.locale]"/>
            </template>
            <template v-else>
              <under-development :description="$t('string.noResultFound')"/>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UnderDevelopment from '@/components/UnderDevelopment'
import LocalizationSwitcher from '@/views/admin/_components/LocalizationSwitcher'
import ProjectBuildingDetail from '@/components/ProjectBuildingDetail'

export default {
  name: 'Project',
  components: {
    ProjectBuildingDetail,
    LocalizationSwitcher,
    UnderDevelopment
  },
  data () {
    return {
      project: null,
      owners: null,
      baseUrl: process.env.VUE_APP_API,
      isLoading: false,
      data: null,
      endPoint: 'show',
      message: null
    }
  },
  computed: {
    images () {
      if (this.project && this.project.cci_images) {
        return this.project.cci_images.map((item) => {
          return `${this.baseUrl}/${item.src}`
        })
      }
      return []
    }
  },
  watch: {
    '$i18n.locale' () {
      this.setOwners()
    },
    project () {
      this.setOwners()
    }
  },
  methods: {
    async getProject () {
      this.isLoading = true
      this.$isLoading(true)
      this.$axios.post(`${process.env.VUE_APP_API}/project/${this.endPoint}`, this.data)
        .then((response) => {
          if (response.data) {
            this.project = response.data
          }
        }).catch((error) => {
          this.message = error.response.data.message
        }).finally(() => {
          this.$isLoading(false)
          this.isLoading = false
        })
    },
    setOwners () {
      this.owners = null
      if (this.project && this.project.owners && Array.isArray(this.project.owners)) {
        this.owners = this.project.owners.map((item) => item['name_' + this.$i18n.locale]).join(', ')
      }
    }
  },
  created () {
    if (this.$route.name === 'view-project') {
      this.endPoint = 'show'
      this.data = { land_unique_number: this.$route.params.land_unique_number }
    } else if (this.$route.name === 'public-view-project') {
      this.endPoint = 'public-show'
      this.data = { access_token: this.$route.params.token }
    }
  },
  async mounted () {
    this.message = null
    await this.getProject()
  }
}
</script>

<style>
.project-content {
  background: #fff;
  box-shadow: 0 8px 36px #ddd;
  margin-top: 35px;
  margin-bottom: 35px;
  border-radius: 4px;
  padding-bottom: 35px;
}

.qr-code-tips-container {
  background-color: #3c8dbc;
  padding: 5px;
  width: 100%;
}

.qr-tips-occupancy {
  width: 100%;
  color: #ffffff;
  padding-top: 10px;
  font-size: 22px;
}

.img-responsive {
  width: 100%;
  height: auto;
}
</style>
