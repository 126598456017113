<template>
  <div class="qr-code-container">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="qr-header">
            <div>
              <img src="https://api-ccinspection.asoradev.com/img/logo/MLMUPC.png" class="logo"/>
            </div>
            <div class="qr-header-brand">
              <div class="text-center">
                <h2 class="font-header">ក្រសួងរៀបចំដែនដី នគរូបនីយកម្ម និងសំណង់</h2>
                <h4 class="font-en">Ministry of Land Management, Urban Planning and Construction</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped>
.qr-code-container {
  background: var(--blue);
  color: var(--white);
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}

.qr-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 10rem;
  height: auto;
}

@media screen and (max-width: 1200px) {
  .qr-header {
    display: block;
    text-align: center;
  }
}
</style>
